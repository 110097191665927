/* @import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap"); */
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

:root {
  font-size: 16px;
}

body {
  overflow-x: hidden;
  background: #fff;
  /* font-family: "Roboto", sans-serif; */
  font-family: "Poppins", sans-serif !important;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

main {
  width: 100%;
  max-width: 760px !important;
  margin-inline: auto;
}

ul,
li {
  list-style: none;
}
